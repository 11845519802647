import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { navigate } from 'gatsby'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

const isBrowser = typeof window !== `undefined`

function Page() {
  const { loginUrl } = useStoreConfig()

  if (!isBrowser) {
    return null
  }

  if (document.cookie.indexOf('memberAuthToken') < 0) {
    window.location.href = loginUrl

    return null
  }

  navigate('/')

  return (
    <>
      <GatsbySeo noindex nofollow />

      <div>loading...</div>
    </>
  )
}

export default Page
